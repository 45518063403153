button {
	background-color: white;
	color: black;
	font-family: "Trebuchet MS";
	font-size: 15px;
	width: 162px;
	height: 50px;
	border-style: solid;
	border-radius: 15px;
	border-width: 4px;
	border-color: black;
	cursor: pointer;
}

button:hover {
	background-color: #d3d3d3;
}

.container {
	display: grid;
	grid-template-columns: 1fr 600px 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 10px;
}

.left_col {
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
	align-self: center;
	justify-self: center;
}

.middle_col {
	grid-column: 2 / span 1;
	grid-row: 1 / span 1;
}

.right_col {
	grid-column: 3 / span 1;
	grid-row: 1 / span 1;
	align-self: center;
	/*justify-self: center;*/
}

h3 {
	width: 50%;
	padding-left: 25%;
	font-family: "Trebuchet MS";
}

h4 {
	width: 50%;
	padding-left: 25%;
	font-family: "Trebuchet MS";
}

p {
	width: 50%;
	padding-left: 25%;
	font-family: "Trebuchet MS";
}

ul {
	width: 50%;
	padding-left: 25%;
	margin-left: 1rem;
}

li {
	font-family: "Trebuchet MS";
}

.DrongoTerm {
	width: 90%;
	padding-left: 5%;
	border-style: solid;
	border-color: black;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	margin-bottom: 0;
	border-bottom: none;
}

#log {
	margin-top: 0;
	width: 90%;
	height: 200px;
	padding-left: 5%;
	border-style: solid;
	border-color: black;
}
