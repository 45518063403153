.bimg {
	z-index: 2;
}

.attribution {
	position: fixed;
	right: 5px;
	bottom: 10px;
	z-index: 3;
	padding: 4px;
	border-radius: 3px;
	font-family: "Trebuchet MS";
	background-color: white;
	box-shadow: 0px 0px 8px 10px white;
	/*background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));*/
}

.Cocoa {
	width: 40%;
	padding-left: 30%;
	padding-top: 20px;
}