html {
	scroll-behavior: smooth;
}

button {
	background-color: white;
	color: black;
	font-family: "Trebuchet MS";
	font-size: 15px;
	width: 100%;
	height: 50px;
	border-style: solid;
	border-radius: 15px;
	border-width: 4px;
	border-color: black;
	cursor: pointer;
}

button:hover {
	background-color: #d3d3d3;
}

.nyansim-container {

}

.assembly-code {
	margin-top: 10px;
	width: 100%;
	height:  300px;
	font-family: "Courier";
	font-size: 15px;
	white-space:pre-wrap;
	overflow-y: scroll;
}

.reg-qvga-container {
	display: flex;
	align-items: center;
	width: 100%;
	min-width: 600px;
	max-width: 1200px;
	position: relative;
	gap: 10px;
}

.registers {
	display: flex;
	flex-direction: column;
}

.qvga-flex-wrapper {
	width: 600px;
	flex-grow: 12;
}

.qvga-controls-container {
	display: flex;
	flex-direction: column;
	width: 100px;
	flex-grow: 1;
}