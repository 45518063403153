.qvga-wrapper {
	width: 100%;
	box-sizing:  border-box;
}

.qvga-container {
	position:  relative;
	width:  100%;
	height: 100%;
	padding-bottom: 73%;
	background-image: url('board.svg');
	background-repeat: no-repeat;
	background-size: contain;
}

.qvga-content-wrapper {
	position: absolute;
	box-sizing: border-box;
	top: 0; bottom: 0; left: 0; right: 0;
}

.LEDbar {
	position: absolute;
	left: 2%;
	top: 57%;
	width: 7.84%;
	height: 2.68%;
	background-color: white;
	display: flex;
	justify-content: space-around;
}

.QVGAled {
	background-color: white;
	height: 100%;
	width: 50%;
	filter: blur(5px);
}

.daughter-button-wrapper {
	position: absolute;
	width: 2.94%;
	height: 4.03%;
}

.daughter-button {
	width:  100%;
	height: 100%;
	pointer-events: none;
	object-fit: fill;
	user-select: none;
}

#P0_10 {
	left: 2%;
	top: 67%;
}

#P0_11 {
	left: 6.5%;
	top: 67%;
}

.lcd {
	position: absolute;
	width:  25.12%;
	height:  46.9%;
	/*width: 320px;*/
	/*height: 240px;*/
	left: 63.5%;
	/*left:  100px;*/
	top: 22.15%;
	visibility: hidden;
}

.daughter-ladder {
	display: flex;
	position: absolute;
	flex-direction: column-reverse;
	width: 1%;
	height: 13%;
	justify-content: space-between;
	left: 12.5%;
	top: 37%;
}

.ladder-led-wrapper {
	width: 100%;
	height: 5%;
	background-color: whitesmoke;
}

.qvga-led-wrapper {
	position: absolute;
	width: 1%;
	height: 0.6%;
	background-color: whitesmoke;
}

.ladder-led {
	width: 100%;
	height: 100%;
	background-color: whitesmoke;
	filter: blur(1px);
}