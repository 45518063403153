a {
	color: #400040;
	text-decoration: none;
}

.grid {
	display: grid;
	grid-template-columns: 200px 1fr;
}

.menu {
	grid-column: 1 / span 1;
	position: fixed;
	display: grid;
	grid-template-rows: 2fr 2fr 2fr;
	grid-template-columns: 1fr;

}

.menu h1 {
	font-family: "Brush Script MT";
	font-size: 72px;
	grid-row: 1 / span 1;
	text-decoration: none;
	grid-column: 1 / span 1;
	text-align: right;
}

.subpages {
	font-family: "Trebuchet MS";
	grid-row: 2 / span 1;
	grid-column: 1 / span 1;
	text-align: right;
	padding-left: 20px;
}

.content {
	/*display: inline;*/
	grid-column: 2 / span 1;
	grid-row: 1 / span 1;
	/*height: 100%;*/
	/*font-family: cursive;*/
	/*overflow-y: scroll;*/
	overflow-x: hidden;
}

