.email {
	width: 40%;
	height: auto;
	margin-left: 30%;
	margin-top: 150px;
	border-style: solid;
	border-radius: 15px;
	border-color: black;
}

p.text {
	width: 50%;
	padding-left: 25%;
	padding-top: 50px;
	font-family: "Trebuchet MS";
	text-align: center;
}