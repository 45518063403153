.potentiometer-wrapper {
	position: absolute;
	width: 4.9%;
	height: 6.67%;
	border-radius: 50%;
}

.potentiometer {
	width: 100%;
	height: 100%;
	user-select: none;
}

.potentiometer-dummy {
	position: absolute;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	background-color: #00000000;
}