.light-sensor-container {
	position: relative;
	flex-grow: 1;
	width: 150px;
}

.light-sensor {
	width: 100%;
	user-select: none;
	object-fit: fill;
}

.light-sensor-shadow {
	position: absolute;
	height: 5vw;
	width: 5vw;
	background-color: black;
	border-radius: 2.5vw;
	filter: blur(1.5vw);
	pointer-events: none;
	box-sizing: border-box;
}

.light-sensor-dummy {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
}