.board {
	/*position: relative;*/
	display: grid;
	width: 600px;
	height: 550px;
	/*padding-top: 50%;*/
	grid-template-rows: repeat(13, 1fr);
	grid-template-columns: repeat(15, 1fr);
	background-color: #094D1C;
	
	/*padding-left: 25%;*/
	margin: auto;
}

.pmod {
	height: 100%;
	width: 100%;
	background-color: black;
}

#pmod1 {
	grid-row: 1 / span 1;
	grid-column: 4 / span 2;
}

#pmod2 {
	grid-row: 1 / span 1;
	grid-column: 7 / span 2;
}

#pmod3 {
	grid-row: 1 / span 1;
	grid-column: 10 / span 2;
}

#pmod4 {
	grid-row: 1 / span 1;
	grid-column: 13 / span 2;
}

.power {
	height: 100%;
	width: 70%;
	background-color: black;
	grid-row: 1 / span 1;
	grid-column: 3 / span 1;
	margin: auto;
}

.powerSwitch {
	height: 85%;
	width: auto;
	grid-row: 2 / span 2;
	grid-column: 2 / span 1;
	margin: auto;
}

.uUSB {
	height: 50%;
	width: 50%;
	background-color: grey;
	grid-row: 4 / span 1;
	grid-column: 1 / span 1;
	align-self: center;
}

.xilinx {
	height: 100%;
	width: 100%;
	grid-row: 3 / span 1;
	grid-column: 5 / span 6;
	margin: auto;
}

.reset {
	height: auto;
	width: 90%;
	grid-row: 4 / span 1;
	grid-column: 13 / span 1;
	margin: auto;
}

.dpi {
	height: 100%;
	width: 80%;
	background-color: grey;
	grid-row: 4 / span 5;
	grid-column: 15 / span 1;
	justify-self: right;
}

.ethernet {
	height: 100%;
	width: 90%;
	background-color: grey;
	grid-row: 5 / span 2;
	grid-column: 1 / span 2;
}

.chip {
	height: 100%;
	width: 100%;
	background-color: black;
	grid-row: 5 / span 3;
	grid-column: 7 / span 3;
}

.nexys {
	height: auto;
	width: 90%;
	grid-row: 6 / span 1;
	grid-column: 10 / span 5;
	margin: auto;
}

.vga {
	height: 100%;
	width: 90%;
	background-color: black;
	grid-row: 7 / span 3;
	grid-column: 1 / span 2;
}

#AN3 {
	grid-row: 8 / span 2;
	grid-column: 11 / span 1;
}

#AN2 {
	grid-row: 8 / span 2;
	grid-column: 12 / span 1;
}

#AN1 {
	grid-row: 8 / span 2;
	grid-column: 13 / span 1;
}

#AN0 {
	grid-row: 8 / span 2;
	grid-column: 14 / span 1;
}

.digilent {
	height: auto;
	width: 100%;
	grid-row: 9 / span 1;
	grid-column: 4 / span 5;
}

.USB {
	height: 100%;
	width: 90%;
	background-color: grey;
	grid-row: 10 / span 2;
	grid-column: 1 / span 2;
}

#grp7 {
	grid-row: 11 / span 1;
	grid-column: 3 / span 1;
}

#grp6 {
	grid-row: 11 / span 1;
	grid-column: 4 / span 1;
}

#grp5 {
	grid-row: 11 / span 1;
	grid-column: 5 / span 1;
}

#grp4 {
	grid-row: 11 / span 1;
	grid-column: 6 / span 1;
}

#grp3 {
	grid-row: 11 / span 1;
	grid-column: 7 / span 1;
}

#grp2 {
	grid-row: 11 / span 1;
	grid-column: 8 / span 1;
}

#grp1 {
	grid-row: 11 / span 1;
	grid-column: 9 / span 1;
}

#grp0 {
	grid-row: 11 / span 1;
	grid-column: 10 / span 1;
}

.led {
	height: 30%;
	width: 30%;
	margin: auto;
}

.switchLabel {
	font-family: monospace;
	color: white;
	text-align: center;
}

#SW7 {
	grid-row: 12 / span 2;
	grid-column: 3 / span 1;
}

#SW6 {
	grid-row: 12 / span 2;
	grid-column: 4 / span 1;
}

#SW5 {
	grid-row: 12 / span 2;
	grid-column: 5 / span 1;
}

#SW4 {
	grid-row: 12 / span 2;
	grid-column: 6 / span 1;
}

#SW3 {
	grid-row: 12 / span 2;
	grid-column: 7 / span 1;
}

#SW2 {
	grid-row: 12 / span 2;
	grid-column: 8 / span 1;
}

#SW1 {
	grid-row: 12 / span 2;
	grid-column: 9 / span 1;
}

#SW0 {
	grid-row: 12 / span 2;
	grid-column: 10 / span 1;
}

#BTNU {
	grid-row: 11 / span 1;
	grid-column: 13 / span 1;
}

#BTNL {
	grid-row: 12 / span 1;
	grid-column: 12 / span 1;
}

#BTNS {
	grid-row: 12 / span 1;
	grid-column: 13 / span 1;
}

#BTNR {
	grid-row: 12 / span 1;
	grid-column: 14 / span 1;
}

#BTND {
	grid-row: 13 / span 1;
	grid-column: 13 / span 1;
}